export const parseHtml = (htmlString) => {
  const replacedHtmlString = htmlString?.replace(
    /<img.*?src="(.*?)"[^\>]+>/g,
    "+?+?+$1+?+?+"
  );

  return replacedHtmlString;
};

export const isIframe = (htmlString) => {
  const replacedHtmlString = htmlString?.replace(
    /<iframe.*?src="(.*?)"[^\>]+>/g,
    "#?#?#$1#?#?#"
  );

  return replacedHtmlString;
};
